*{
    margin:0;
    padding:0;
    box-sizing: border-box;
    outline:none;
    color:white;
}
:root{
    --bg--color:rgb(70, 70, 70);
}
body{
    background-color:var(--bg--color);
}
.App{
    display:flex;
    height: 100vh;
    scroll-behavior: smooth;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap:10px;
}
.Add-task{
    display:flex;

}
.Add-task input{
    width:50vw;
    min-width:250px;
    max-width:800px;
    padding:10px;
    height: 40px;
    background-color: var(--bg--color);
    border:1px solid white;
}
.Add-task button{
    width:10vw;
    min-width: 50px;
    max-width:160px;
    height: 40px;
    background-color: var(--bg--color);
    border:1px solid white;
    cursor: pointer;
    font-weight: 900;
    border-radius:0.8vh ;
    margin-left:0.2vw;
}
.Add-task button:hover{
    background-color: rgb(122, 122, 122);
}
.searchinput{
    width:clamp(300px , 60vw , 960px);
    height: 30px;
    background-color:transparent;
    padding:10px;
    border:#fff solid 2px;
    border-radius:1vh;
}
.maininput{
    border-radius:1vh;
}
